import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import ImageViewer from "react-simple-image-viewer";
import "./VirtualSensor.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "../Calender/DatePicker";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import InputField from "../InputField/InputField";
import { Icon } from "@iconify/react";
import Loader from "react-loader-spinner";
import Messages from "../Messages/Messages";
import ChatInterface from "../Comments/Comments";
import { objectDiff, dateDifference } from "../../helpers/Utils";
import leftArrow from "../../assets/left-arrow.png";
import rightArrow from "../../assets/right-arrow.png";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { View, StyleSheet } from "react-native";
import CalendarStrip from "react-native-calendar-strip";
import ReactTooltip from "react-tooltip";
import debounce from "lodash.debounce";
import { MyContext } from "../Dashboard/Dashboard.js";
import { InputTextarea } from "primereact/inputtextarea";
import CloseButton from "react-bootstrap/CloseButton";
import useNetwork from "../../customHooks/useNetwork";
import io from "socket.io-client";
import db from "../../DexieDBConfig";
import { Calendar } from "primereact/calendar";
import { uploadOpsFile } from "../Comments/CommentService";
import { getTimeZones } from "@vvo/tzdb";
import ct from "countries-and-timezones"
import { Dropdown } from "primereact/dropdown";
// import { Agenda } from "react-native-calendars";
let stop = false;
const SENSOR_FETCH = gql`
  query virtualSensorByVesselRoom(
    $customerId: ID!
    $vesselId: ID!
    $startTime: Float!
    $endTime: Float!
    $roomId: ID!
  ) {
    virtualSensorByVesselRoom(
      customerId: $customerId
      vesselId: $vesselId
      startTime: $startTime
      endTime: $endTime
      roomId: $roomId
    ) {
      userName
      finishedTime
      sensorData {
        id
        value
        lastSyncedValue
        lastSyncedTime
      }
    }
  }
`;
const comp_image_query = gql`
  query componentsImage(
    $customerId: ID!
    $roomId: ID!
    $componentId: ID!
    $isMrvEnabled: Boolean!
  ) {
    componentsImage(
      customerId: $customerId
      roomId: $roomId
      componentId: $componentId
      isMrvEnabled: $isMrvEnabled
    )
  }
`;

const virtualSensorDays = gql`
  query virtualSensorDataDays(
    $vesselId: ID!
    $customerId: ID!
    $roomId: ID!
    $startDuration: Float!
    $endDuration: Float!
    $docType: String!
  ) {
    virtualSensorDataDays(
      vesselId: $vesselId
      customerId: $customerId
      docType: $docType
      virtualSensorDataInput: {
        roomId: $roomId
        startDuration: $startDuration
        endDuration: $endDuration
      }
    )
  }
`;

const componentImageQuery = gql`
  query componentsImage($customerId: ID!, $roomId: ID!, $componentId: ID!) {
    componentsImage(
      customerId: $customerId
      roomId: $roomId
      componentId: $componentId
    )
  }
`;

const getTime = () => {
  const date = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return {
    time: strTime,
    weekDay: days[date.getDay()],
    monthDate: date.getDate(),
    month: monthNames[date.getMonth()],
  };
};
var savedFinishTime;
let images = [];
const VirtualSensor = (props) => {
  let { setBgColor, bvRoom, setBvRoom } = useContext(MyContext);
  const debouncedSave = useDebounce(
    (nextValue) => getWeekDates(nextValue),
    500
  );
  const networkState = useNetwork();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { online } = networkState;

  const calenderRef = useRef(null);
  //const [trigger, getCommentUnsubmitted] = React.useState(null);
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [finishedTime, setFinishedTime] = useState();
  const [sensorDetails, setSensorDetails] = useState({});
  const [userName, setUserName] = useState("");
  const [currentTime, setCurrentTime] = useState(getTime());
  const [formData, setFormData] = useState({});
  const [syncedData, setSyncedData] = useState({});
  const [submittedData, setSubmittedData] = useState({});
  const [customDatesStyles, setCustomDatesStyles] = useState([]);
  const [customServerStyles, setCustomServerStyles] = useState([]);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const [prevDateTime, setPrevDateTime] = useState(null);
  const [timePicker, showTimePicker] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format("L"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [localDates, setLocalDates] = useState([]);
  const [redDates, setCustomRedDatesStyles] = useState([]);
  const [commentInput, setComment] = useState("");
  const [showCommentsModal, enablePopUp] = useState(false);
  const maxDate = moment(new Date());
  const [components, setComponents] = useState([]);
  const [socketData, setSocetMessage] = useState({});
  /*For Upload */
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadInprogress, setUploadInprogress] = useState(false);
  /**For Offhire_Reasons Text capture */
  const [offhireHours, setOffHireHours] = useState(null);
  const [docType, setLogbookType] = useState("");
  const [validArrival, setValidArrival] = useState(null);
  const [validDeparture, setValidDeparture] = useState(null);
  const [selectedTZ, setSelectedTZ] = useState(null);
  const [timezones, setTimeZones] = useState([]);
  const {
    match: { params },
  } = props;
  const { vid, cid, rid } = params;
  const virtualSensor_query = gql`
  query {componentsVirtualSensorByVesselRoom(vesselId:${vid},roomId:${rid},customerId:${cid},isMrvEnabled:${bvRoom}) { 
    name,
    id,
    code,
    Sensor {
        sensorId,
        id,
        name,
        showLastValue,
          properties { 
            type,
            options,
            validations
          }
        }
      }
    }`;
  const Socket_query = gql`
    query AppConfigData($appCode: String!) {
      appConfigData(appCode: $appCode) {
        socketUrl
      }
    }
  `;

  const [getAppConfigDetails, {}] = useLazyQuery(Socket_query, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      let url = resp.appConfigData.socketUrl
        ? resp.appConfigData.socketUrl
        : null;
      const socket = io(url, {
        auth: {
          token: localStorage.getItem("access_token"),
        },
        query: {
          vesselId: vid,
          application: btoa(localStorage.getItem("application")),
        },
        transports: ["websocket"],
      });

      socket.on(vid, (data) => {
        setSocetMessage(data);
      });

      return () => {
        socket.disconnect();
      };
    },
  });

  useEffect(() => {
    const tsz = getTimeZones();
    
    setTimeZones(tsz);
    let curentTimeZone = new Date().getTimezoneOffset();
    let timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneId = ct.getCountryForTimezone(timezoneName) && ct.getCountryForTimezone(timezoneName).id;
    tsz.map((zone) => {
      if (timezoneId && zone.group.includes(timezoneName) && zone.countryCode == timezoneId) {
        setSelectedTZ(zone);
      }
      if (timezoneId == null && zone.group.includes(timezoneName)) {
        setSelectedTZ(zone);
      }
    });
    getAppConfigDetails({
      variables: {
        appCode: "OPAPP",
      },
    });
    let mrvEnabled = localStorage.getItem("mrvRoomEnabled");
    if (mrvEnabled == "true") {
      setLogbookType("mrvbvlogbook");
      setBvRoom(true);
    } else {
      setLogbookType("vessellogbook");
      setBvRoom(false);
    }
  }, []);

  useEffect(() => {
    setBgColor("#ffffff");
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    setInterval(() => {
      setCurrentTime(getTime());
    }, 60000);
    if (online) {
      getComponents();
    } else {
      db.userInfo.toArray().then((users) => {
        let theUser = localStorage.getItem("offline_user_email")
          ? localStorage.getItem("offline_user_email")
          : localStorage.getItem("user_email");
        users.map((user) => {
          if (user.email == theUser) {
            localStorage.setItem("user_name", user.userName);
            user.response.customersByUser.map((cust) => {
              if (cust.customerId == cid) {
                cust.vessels.map((vessel) => {
                  if (vessel.id == vid) {
                    let rooms = bvRoom ? vessel.bvRooms : vessel.rooms;
                    rooms.map((room) => {
                      if (room.id == rid) {
                        let data = {
                          componentsVirtualSensorByVesselRoom: room.components,
                        };
                        data.componentsVirtualSensorByVesselRoom.map((each) => {
                          db.imageStore.toArray().then((images) => {
                            images.map((image) => {
                              if (image.id == `${vid}-${rid}-${each.id}`) {
                                each.image = image.url;
                              }
                            });
                          });
                        });
                        setComponents(data);
                        getformData();
                      }
                    });
                  }
                });
              }
            });
          }
        });
      });
    }

    //getCommentUnsubmitted();
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);

  const [getComponents, { loading, error, data }] = useLazyQuery(
    virtualSensor_query,
    {
      fetchPolicy: "network-only",
      onCompleted(resp) {
        getformData();
        let startTime = moment(selectedDate).startOf("day").utc().valueOf();
        let endTime = moment(selectedDate).endOf("day").utc().valueOf();
        let copy = JSON.parse(JSON.stringify(resp));
        copy.componentsVirtualSensorByVesselRoom.map(async (each) => {
          if(!bvRoom){
            let data = await getCompImage({
              variables: {
                customerId: +cid,
                roomId: +rid,
                componentId: each.id,
                isMrvEnabled: bvRoom,
              },
            })
              .then((data) => {
                each.image = data.data.componentsImage;
              })
              .catch((error) => {
                each.image = "Not Available";
              });
          }else{
            each.image = "Not Available";
          }
          

          let data1 = await getSensorValues({
            variables: {
              customerId: cid,
              vesselId: vid,
              startTime: startTime,
              endTime: endTime,
              roomId: rid,
            },
          })
            .then((data1) => {
              each.Sensor.map((eachSensor) => {
                data1.data.virtualSensorByVesselRoom.sensorData.map(
                  (eachItem) => {
                    if (eachSensor.id == eachItem.id) {
                      if (eachSensor.showLastValue) {
                        eachSensor.lastSyncedValue = eachItem.lastSyncedValue;
                        eachSensor.value = eachItem.value;
                        eachSensor.lastSyncedTime = moment(
                          eachItem.lastSyncedTime
                        ).format("YYYY/MM/DD hh:mm a");
                      }
                    }
                    //  else{
                    //   eachSensor.showLastMessage=""
                    //  }

                    // eachSensor.showLastValue=eachItem.showLastValue;
                    // }
                  }
                );
              });
            })
            .catch((error) => {
              each.sensorDetails = {};
            });

          if (each.image !== "Not Available") {
            let image = {
              url: each.image,
              id: `${vid}-${rid}-${each.id}`,
            };
            db.imageStore.add(image, `${vid}-${rid}-${each.id}`);
          }
          let copy2 = JSON.parse(JSON.stringify(copy));
          setComponents(copy2);
        });
        setComponents(copy);
      },
    }
  );

  const openImageViewer = useCallback((index, image) => {
    images[0] = image;
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [getCompImage, { loadingImg, errorImg, dataImg }] = useLazyQuery(
    comp_image_query,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data) {
          return data.componentsImage;
        }
      },
      onError: (error) => {},
    }
  );

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  function useDebounce(callback, delay) {
    const debouncedFn = useCallback(
      debounce((...args) => callback(...args), delay),
      [delay] // will recreate if delay changes
    );
    return debouncedFn;
  }

  const setFiles = (files) => {
    let upf = [...uploadFiles];
    let duplicate = false;
    upf.map((file, i) => {
      if (file.id == files.id) {
        upf[i] = files;
        duplicate = true;
      }
    });
    if (!duplicate) {
      upf.push(files);
    }
    setUploadFiles(upf);
  };

  const cancelCommentAdd = () => {
    enablePopUp(false);
  };

  //vessel id
  // const { vid, cid, rid } = params;
  // const { loading, error, data } = useQuery(virtualSensor_query);

  const [
    getSensorValues,
    {
      loading: sensorValueLoading,
      error: sensorValueError,
      data: sensorValueData,
    },
  ] = useLazyQuery(SENSOR_FETCH, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      setUserName("");
      setFinishedTime();
      if (resp && resp.virtualSensorByVesselRoom) {
        let sensorValues = {};

        setUserName(resp.virtualSensorByVesselRoom.userName);

        if (resp.virtualSensorByVesselRoom.finishedTime) {
          setFinishedTime(
            moment(resp.virtualSensorByVesselRoom.finishedTime).format(
              "YYYY/MM/DD hh:mm a"
            )
          );
          setPrevDateTime(
            new Date(resp.virtualSensorByVesselRoom.finishedTime)
          );
        } else {
          setPrevDateTime(new Date(selectedDate));
        }

        setSensorDetails(resp.virtualSensorByVesselRoom.sensorData[0]);
        savedFinishTime = moment(
          resp.virtualSensorByVesselRoom.finishedTime
        ).format("YYYY/MM/DD hh:mm a");

        resp.virtualSensorByVesselRoom.sensorData.forEach((data) => {
          sensorValues = { ...sensorValues, [data.id]: data.value };
        });
        //update syncdb
        let id = `${vid}-${rid}-${selectedDate}`;
        let data = {
          finishedTime: moment(
            resp.virtualSensorByVesselRoom.finishedTime
          ).format("YYYY/MM/DD hh:mm a"),
          userName: resp.virtualSensorByVesselRoom.userName,
        };
        db.syncedOperations
          .put(
            {
              value: sensorValues,
              valueId: id,
              finishedTime: data.finishedTime,
              userName: data.userName,
            },
            id
          )
          .then(
            (event) => {
              fetchFromDb();
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        fetchFromDb();
        setPrevDateTime(new Date(selectedDate));
      }
    },
    onError(err) {
      toast.dismiss();
      toast.error("An error occured. Please try again.");
    }
  });

  // to populate form fields
  const getformData = async () => {
    // check online
    if (isOnline) {
      //calling graphql query
      let startTime = moment(selectedDate).startOf("day").utc().valueOf();
      let endTime = moment(selectedDate).endOf("day").utc().valueOf();
      try {
        await getSensorValues({
          variables: {
            customerId: cid,
            vesselId: vid,
            startTime: startTime,
            endTime: endTime,
            roomId: rid,
          },
        });
      } catch (e) {
        console.log("error fetching sensor");

      }
    } else {
      setPrevDateTime(new Date(selectedDate));
      fetchFromDb();
    }
  };

  const fetchFromDb = () => {
    let id = `${vid}-${rid}-${selectedDate}`;
    let formData = {};
    db.syncedOperations.get(id).then((syncedData) => {
      if (syncedData) {
        setFinishedTime(
          moment(syncedData.finishedTime).format("YYYY/MM/DD hh:mm a")
        );
        savedFinishTime = moment(syncedData.finishedTime).format(
          "YYYY/MM/DD hh:mm a"
        );
        setUserName(syncedData.userName);
        setSyncedData(syncedData.value);
        formData = { ...formData, ...syncedData.value };
      }
      db.submittedOperations.get(id).then(
        (submittedData) => {
          if (submittedData) {
            setSubmittedData(submittedData.value);
            formData = { ...formData, ...submittedData.value };
          }
          let difference = dateDifference(selectedDate, moment().format("L"));
          // if (difference > 0) {
          //   setFormData(formData);
          // } else {
          db.unsyncedOperations.toArray().then(
            (unSubmittedData) => {
              if (unSubmittedData.length > 0) {
                unSubmittedData.map((item) => {
                  let selDate = moment(item.UTCtimeStamp).format("MM/DD/YYYY");
                  if (item.VRid == `${vid}-${rid}` && selDate == selectedDate) {
                    setFinishedTime(
                      moment(item.UTCtimeStamp).format("YYYY/MM/DD hh:mm a")
                    );
                    setUserName(item.user ? item.user : "");
                    // setFinishedTime(moment(item.UTCtimeStamp).format("YYYY/MM/DD hh:mm a"))

                    let data = item;
                    if (data) {
                      let date1 = new Date(data.UTCtimeStamp);
                      let date2 = new Date(selectedDate);
                      let diff = date2.getDate() - date1.getDate();
                      if (data && Math.abs(diff) < 1) {
                        formData = { ...formData, ...data.value };
                        setFormData(formData);
                      } else {
                        setFormData(formData);
                      }
                    }
                  } else {
                    setFormData(formData);
                  }
                });
              } else {
                setFormData(formData);
              }
            },
            (err) => {}
          );
          // }
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  };

  const clean = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === "null" ||
        obj[propName] === null ||
        obj[propName] === "" ||
        obj[propName] === "undefined"
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  // form submit to save indexeddb
  const onSubmit = (data) => {
    // converting all values to string format

    if (bvRoom && validDeparture !== null && validDeparture == false) {
      toast.warning("Port of departure is invalid!");
      return;
    }

    if (bvRoom && validArrival !== null && validArrival == false) {
      toast.warning("Port of arrival is invalid!");
      return;
    }

    Object.keys(data).forEach((key) => {
      data[key] = `${data[key]}`;
      data[key] = data[key].trim();
      // if (offhireHours && offhireHours.name && key == offhireHours.name) {
      //   data[key] = data[key] + " " + `${offhireHours.value} hours`;
      // }
    });

    let gid = `${vid}-${rid}-${moment().utc().valueOf()}`;

    uploadFiles.map((file) => {
      let today = new Date();
      let date = moment(today).format("YYYY/MM/DD");
      let prevDate = moment(prevDateTime).format("YYYY/MM/DD");
      let epoch = moment(today).valueOf();
      if (date !== prevDate) {
        epoch = moment(prevDateTime).valueOf();
        date = prevDate;
      }
      let filePath =
        date + "/" + file.data.fileName + "_" + epoch + "." + file.data.format;
      data[file.id] = file.data.fileName + "_" + epoch + "." + file.data.format;
      let id = `${vid}-${rid}-${moment().utc().valueOf()}`;

      if (online) {
        setUploadInprogress(true);
        const formData = new FormData();
        formData.append("file", file.data.onlineFile);
        formData.append("filePath", filePath);
        formData.append("type", "vsensorupload");
        formData.append("relativeFolderPath", file.data.relativeFolderPath);
        uploadFile(file, gid, formData);
      } else {
        let fileToStore = file;
        fileToStore.id = id;
        fileToStore.gid = gid;
        fileToStore.filePath = filePath;
        db.uploadFiles.add(fileToStore, id).then((event) => {});
      }
    });
    setUploadFiles([]);
    const valueData = clean(data);
    let modifiedData = valueData;
    let date = moment().utc().milliseconds(0).valueOf();

    setUserName(
      localStorage.getItem("user_name") ? localStorage.getItem("user_name") : ""
    );

    let notSameDay =
      moment(date).format("YYYY-MM-DD") ==
      moment(prevDateTime).format("YYYY-MM-DD")
        ? false
        : true;
    setFinishedTime(
      moment(notSameDay ? prevDateTime : date).format("YYYY/MM/DD hh:mm a")
    );
    if (Object.keys(modifiedData).length !== 0) {
      db.unsyncedOperations
        .put(
          {
            valueId: gid,
            value: modifiedData,
            UTCtimeStamp: notSameDay
              ? moment(prevDateTime).utc().milliseconds(0).valueOf()
              : date,
            correctedTime: date,
            timeZoneOffset: selectedTZ ? selectedTZ.currentTimeOffsetInMinutes : new Date().getTimezoneOffset(),
            Uid: localStorage.getItem("user_uid"),
            VRid: `${vid}-${rid}`,
            user: localStorage.getItem("user_name")
              ? localStorage.getItem("user_name")
              : "",
            type: bvRoom ? "bv" : "",
          },
          gid
        )
        .then(
          (event) => {
            toast.dismiss();
            toast.success("Finished Successfully!");

            let dots = [...customDatesStyles];
            dots.unshift({
              date: notSameDay
                ? moment(prevDateTime).utc().milliseconds(0).valueOf()
                : date,
              dots: [
                {
                  color: "rgb(93, 229, 253)",
                },
              ],
            });

            setCustomDatesStyles(dots);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.dismiss();
      toast.warning("Nothing to Finish!");
    }
    // }
  };

  const uploadFile = async (file, gid, formData) => {
    try {
      const response = await uploadOpsFile(
        file.data.vid,
        file.data.code,
        formData
      );
      setUploadInprogress(false);
    } catch (error) {
      setUploadInprogress(false);
      let toBeUpdated;
      db.unsyncedOperations.toArray().then((data) => {
        data.map((each) => {
          if (each.valueId == gid) {
            toBeUpdated = each;
            delete toBeUpdated.value[file.id];
            db.unsyncedOperations.put(toBeUpdated, gid);
            toast.error(`${file.value} upload failed. Please try again!`);
          }
        });
      });
    }
  };

  const styles = StyleSheet.create({
    container: { flex: 1 },
  });

  // to clean up on unmount
  useEffect(() => {
    return () => {};
  }, []);

  const clearObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] == null) {
        delete obj[key];
      }
    });
    return obj;
  };
  const openCommentAdd = () => {
    setComment("");
    enablePopUp(true);
  };
  useEffect(() => {
    // disable prev data
    let difference = dateDifference(selectedDate, moment().format("L"));
    if (difference > 0) {
      setDisableField(false);
      showTimePicker(true);
    } else {
      setDisableField(false);
      showTimePicker(false);
    }
    setFormData({});
    getformData();
    setUploadFiles([]);
  }, [selectedDate]);

  /* Calendar Color Changes */
  useEffect(() => {
    let custDateStyles = [...customServerStyles];
    let startDate = moment();
    custDateStyles.push({
      startDate: maxDate, // Single date since no endDate provided
      dateNameStyle: { color: "#ffffff", fontSize: 11 },
      dateNumberStyle: { color: "#ffffff", fontSize: 15 },
      dateContainerStyle: {
        backgroundColor: "orange",
      },
    });
    // }
    setCustomServerStyles(custDateStyles);
  }, []);

  const selectedDay = (val) => {
    setSelectedDate(moment(val).format("L"));
    setTimeout(() => {
      stop = false;
    }, 3000);
  };
  let virtualSensorStatus = gql`
  {
    virtualSensorDataDays(
      vesselId: ${vid}
      customerId: ${cid}
      virtualSensorDataInput: {
        roomId: ${rid}
        startDuration: ${startDate}
        endDuration: ${endDate}
      }
    )
  }
`;
  const [getDateStatus, { loading: datesLoading, dateData }] = useLazyQuery(
    virtualSensorDays,
    {
      fetchPolicy: "network-only", // Used for first execution
      // nextFetchPolicy: "cache-first",
      onCompleted(resp) {
        if (resp && resp.virtualSensorDataDays) {
          let dates = resp.virtualSensorDataDays;
          let custDateStyles = [];
          let startDate = moment();
          dates.map((date) => {
            custDateStyles.push({
              date: date,
              dots: [
                {
                  color: "green",
                  // selectedColor: <string> (optional),
                },
              ],
            });
          });
          custDateStyles = [...redDates, ...custDateStyles];
          setCustomDatesStyles(custDateStyles);
        } else {
        }
      },
      onError(err) {
        setCustomDatesStyles([]);
      },
    }
  );

  const getServerDates = async () => {
    if (isOnline) {
      try {
        await getDateStatus({
          variables: {
            vesselId: vid,
            customerId: cid,
            roomId: rid,
            startDuration: startDate,
            endDuration: endDate,
            docType: docType,
          },
        });
      } catch (e) {
        console.log("error fetching sensor");
      }
    }
  };

  useEffect(() => {
    if (endDate) {
      getServerDates();
    }
  }, [endDate]);

  const getWeekDates = (value) => {
    let start = moment(value[0])
      .subtract(1, "day")
      .startOf("day")
      .utc()
      .valueOf();
    let end = moment(value[1]).add(1, "day").endOf("day").utc().valueOf();
    setStartDate(start);
    setEndDate(end);
    setCustomRedDatesStyles([]);

    // let id = `${vid}-${rid}-${selectedDate}`;
    let custDateStyles = [];
    let custDateStylesBlue = [];
    db.submittedOperations.toArray().then(
      (submittedData) => {
        if (submittedData) {
          let dates = [];
          submittedData.map((data) => {
            if (
              data.valueId.indexOf(`${vid}-${rid}`) !== -1 &&
              parseInt(data.UTCtimestamp) >= start &&
              parseInt(data.UTCtimestamp) <= end
            ) {
              dates.push(new Date(parseInt(data.UTCtimestamp)));
            }
          });
          dates = [...new Set(dates)];

          dates.map((date) => {
            custDateStyles.push({
              date: moment(date).utc().valueOf(),
              dots: [
                {
                  color: "red",
                  // selectedColor: <string> (optional),
                },
              ],
            });
          });
          if (custDateStyles.length > 0) {
            setCustomRedDatesStyles(custDateStyles);
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
    db.unsyncedOperations.toArray().then(
      (finished) => {
        // let data = finished[finished.length-1];
        if (finished.length > 0) {
          let dates = [];
          finished.map((entry) => {
            if (entry.VRid == `${vid}-${rid}`) {
              dates.push(new Date(parseInt(entry.UTCtimeStamp)));
              dates = [...new Set(dates)];

              dates.map((date) => {
                custDateStylesBlue.push({
                  date: moment(date).utc().valueOf(),
                  dots: [
                    {
                      color: "rgb(93, 229, 253)",
                      // selectedColor: <string> (optional),
                    },
                  ],
                });
              });
            }
          });

          if (custDateStylesBlue.length > 0) {
            setCustomRedDatesStyles(custDateStylesBlue);
          }
        }
      },
      (err) => {}
    );

    setTimeout(() => {
      if (window.navigator.onLine == false) {
        let dates = [...custDateStylesBlue, ...custDateStyles];
        setCustomDatesStyles(dates);
      }
    }, 1000);

    // }
  };

  const setWeek = (start, end) => {
    debouncedSave([start, end]);
  };

  const datesBlacklistFunc = (date) => {
    return (
      moment(date).startOf("day").valueOf() > moment().startOf("day").valueOf()
    ); // disable Saturdays
  };

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  return (
    <div className="sensors-container">
      {uploadInprogress ? (
        <div className="file-loader">
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: "2em", position: "relative", top: "50%" }}
          ></i>
        </div>
      ) : null}

      <div className="header">
        <div className="title">
          <h4 className="welcome">{localStorage.getItem("roomName")}</h4>
          {/* data.componentsVirtualSensorByVesselRoom.map((component, i) => (
          )) */}
          {finishedTime && userName != "" && userName != null ? (
            <p className="finishedUser">
              Room was last finished by {userName} at {finishedTime}
            </p>
          ) : (
            <p className="finishedUser"></p>
          )}
          {finishedTime &&
          finishedTime !== "Invalid date" &&
          (userName == "" || userName == null) ? (
            <p className="finishedUser">Room was finished at {finishedTime}</p>
          ) : (
            <p className="finishedUser"></p>
          )}
          {!finishedTime && !userName ? (
            <p className="finishedUser">Data was not yet entered</p>
          ) : (
            <p className="finishedUser"></p>
          )}
          {finishedTime == "Invalid date" && !userName ? (
            <p className="finishedUser">Data was not yet entered</p>
          ) : (
            <p className="finishedUser"></p>
          )}
        </div>
      </div>
      <div className="overflow">
        {error && <Messages data={error} />}
        {(loading || sensorValueLoading) && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={300000000000000}
            />
          </div>
        )}
        {datesLoading && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={300000000000000}
            />
          </div>
        )}
        {components &&
          components.componentsVirtualSensorByVesselRoom &&
          components.componentsVirtualSensorByVesselRoom.length === 0 && (
            <p>No components to list!</p>
          )}
        <div className={`${showCommentsModal ? "clickedIcon" : ""}`}>
          <div className="tool">
            {!bvRoom ? (
              <span className={`addIcon`}>
                <Icon
                  icon="ion:chatbubbles"
                  className="add-icon"
                  onClick={() =>
                    showCommentsModal ? cancelCommentAdd() : openCommentAdd()
                  }
                />
              </span>
            ) : null}

            <span data-tip data-for="syncInfo" className="tool-tip">
              i
            </span>
            <ReactTooltip
              id="syncInfo"
              type="dark"
              effect="float"
              className="customeTheme"
            >
              {/* <ul> */}
              {/* <div>
                <div className="dot">
                  <div className="red"></div>
                </div>
                <div className="float-right w-120">Data saved offline</div>
              </div> */}
              <div>
                <div className="dot">
                  <div className="green"></div>
                </div>
                <div className="float-right w-120">Data saved online</div>
              </div>
              <div>
                <div className="dot">
                  <div className="blue"></div>
                </div>
                <div className="float-right w-120">Data not yet saved</div>
              </div>
              {/* </ul> */}
            </ReactTooltip>
          </div>
          <View style={styles.container}>
            <CalendarStrip
              datesBlacklist={(date) => datesBlacklistFunc(date)}
              // scrollable={false}
              // scrollerPaging
              onDateSelected={(v) => {
                stop = true;
                selectedDay(v);
              }}
              onWeekChanged={(s, e) => {
                if (!stop) setWeek(s, e);
              }}
              selectedDate={selectedDate}
              customDatesStyles={customServerStyles}
              daySelectionAnimation={{
                type: "border",
                duration: 200,
                borderWidth: 1,
                borderHighlightColor: "black",
              }}
              markedDates={customDatesStyles}
              highlightDateNameStyle={{
                color: "#224691",
                fontSize: 13,
              }}
              highlightDateNumberStyle={{
                color: "#224691",
                fontSize: 17,
              }}
              style={{
                height: 120,
                // border: "5px solid #224691",
                borderRadius: 45,
                paddingTop: 10,
                // paddingBottom: 10,
              }}
              // innerStyle={{ height: 150 }}
              calendarColor={"#ffffff"}
              calendarHeaderStyle={{
                color: "#224691",
                fontSize: 18,
                marginBottom: 15,
              }}
              dateNumberStyle={{ color: "#224691", fontSize: 15 }}
              dateNameStyle={{ color: "#224691", fontSize: 11 }}
              // innerStyle={[]}
              useNativeDriver={false}
              iconLeft={leftArrow}
              iconRight={
                moment(endDate).startOf("day").valueOf() <=
                moment().startOf("day").valueOf()
                  ? rightArrow
                  : []
              }
            />
            {!showCommentsModal ? (
              <div>
                <label className="time-label">Choose Timezone:</label>
                <Dropdown
                  value={selectedTZ}
                  onChange={(e) => setSelectedTZ(e.value)}
                  options={timezones}
                  optionLabel="name"
                  placeholder="Select a Timezone"
                  filter
                  filterBy="abbreviation,alternativeName,name"
                  className="timezones"
                />
              </div>
            ) : null}
            {timePicker && !showCommentsModal ? (
              <div>
                <label className="time-label">Choose Time:</label>
                <Calendar
                  timeOnly
                  hourFormat="12"
                  readOnlyInput
                  showSeconds
                  onChange={(e) => {
                    setPrevDateTime(new Date(e.value));
                  }}
                  value={prevDateTime}
                  className="timezones"
                ></Calendar>
              </div>
            ) : null}
          </View>
        </div>

        <form
          onSubmit={!showCommentsModal && handleSubmit(onSubmit)}
          onKeyDown={(event) => handleKeyDown(event)}
        >
          {components &&
            components.componentsVirtualSensorByVesselRoom &&
            components.componentsVirtualSensorByVesselRoom.map(
              (component, i) => (
                <div
                  key={i}
                  className={`sensor-list ${
                    showCommentsModal ? "disableSensorList" : ""
                  }`}
                >
                  <div className="sensor-container">
                    <div className="sensor-name">{component.name} </div>
                    {!component.image && online && (
                      <div className="d-block cust-loader">
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "2em", display: "table" }}
                        ></i>
                      </div>
                    )}
                    {/* {!component.image && !online && <img src={defImage} id='def-image' alt="No image" className="comp-image" />} */}
                    {/* {component.image == 'Not Available' && <img src={defImage} id='def-image' alt="No image" className="comp-image" />} */}
                    {component.image && component.image !== "Not Available" && (
                      <img
                        onClick={() => openImageViewer(0, component.image)}
                        src={component.image}
                        id="select-image"
                        alt="component image"
                        className="comp-image"
                      />
                    )}
                    {isViewerOpen && (
                      <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                      />
                    )}
                    <div className="sensor-form">
                      {component.Sensor.map((sensor, j) => (
                        <>
                          <InputField
                            key={j}
                            props={props}
                            inputProps={sensor}
                            params={params}
                            disabled={disableField}
                            style={{ opacity: 0.8 }}
                            setValue={setValue}
                            values={formData}
                            code={component.code}
                            register={register}
                            control={control}
                            liveData={socketData}
                            componentId={component.id}
                            uploadFiles={uploadFiles}
                            setUploadFiles={setFiles}
                            selectedDate={selectedDate}
                            prevDateTime={prevDateTime}
                            setOffHireHours={setOffHireHours}
                            setValidDeparture={setValidDeparture}
                            setValidArrival={setValidArrival}
                          />

                          {sensor.lastSyncedValue &&
                            sensor.lastSyncedValue !== "null" && (
                              <div className="sensor-latest-details">
                                <i class="pi pi-info-circle sensor-info"></i>
                                Latest received value was{" "}
                                {sensor.lastSyncedValue} on{" "}
                                {sensor.lastSyncedTime}
                              </div>
                            )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          {showCommentsModal ? (
            <>
              <ChatInterface
                vid={vid}
                rid={rid}
                cid={cid}
                selectedDate={selectedDate}
              ></ChatInterface>
            </>
          ) : (
            <p className=""></p>
          )}

          {!showCommentsModal ? (
            <input
              className={`fin-btn${bvRoom ? "bv" : ""}`}
              type="submit"
              value="Finish"
              disabled={disableField || showCommentsModal}
              style={submitBtnStyle}
            />
          ) : (
            <p></p>
          )}
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
};
const submitBtnStyle = {
  position: "fixed",
  bottom: "26pt",
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: "#FF6302D1",
  width: "107pt",
  height: "49pt",
  border: "none",
  borderRadius: "40px",
  color: "#fff",
  outline: "none",
};

export default VirtualSensor;
