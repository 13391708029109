import React, { useContext, useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { MyContext } from "../Dashboard/Dashboard.js";
// import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import "./NewServiceRequest.scss";
import { useHistory } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Button as Pbutton } from "primereact/button";

const Category_query = gql`
  query {
    serviceRecordTypes {
      name
      description
      code
    }
  }
`;

const ADD_NEW_REQUEST = gql`
  mutation upsertServiceRecord(
    $vesselId: Int!
    $custId: Int!
    $serviceRecordInput: ServiceRecordInput!
  ) {
    upsertServiceRecord(
      vesselId: $vesselId
      custId: $custId
      serviceRecordInput: $serviceRecordInput
    )
  }
`;

const NewServiceRequest = (props) => {
  let { name } = useContext(MyContext);
  let history = useHistory();
  let state = { selectedFiles: [] };
  const {
    match: { params },
  } = props;
  //vessel id
  const { cid, vid } = params;
  let vesselName = localStorage.getItem("vesselName");
  let vesselImage = localStorage.getItem("vesselImage");
  let vesselId = vid;
  let custId = cid;
  let fileUploaded1;
  const [serviceCategory, setserviceCategory] = useState({});
  const [selectedCategory, setselectedCategory] = useState({});
  const [descriptionValue, setDescription] = useState("");
  const [comments, setComments] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSubmitProgress, setProgress] = useState(false);

  const toast = useRef(null);

  useEffect(() => {
    getCategory();
    setIsDisabled(false);
    setProgress(false);
  }, []);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    fileUploaded1 = event.target.files[0];
  };

  const populateCategory = (e) => {
    setselectedCategory(e.value);

    if (e.value == "") {
      errors.category = "Category is required";
    } else {
      errors.category = "";
    }
  };

  const showToaster = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Request Submitted Successfully",
      life: 6000,
    });
  };

  const showToasterFailure = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Request Submission Failed",
      life: 6000,
    });
  };

  const showToasterFailureMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 6000,
    });
  };


  const populateDescriptionValue = (event) => {
    setDescription(event.target.value);
    if (event.target.value == "") {
      errors.description = "Description is required";
    } else {
      errors.description = "";
    }
  };

  const populateComment = (event) => {
    setComments(event.target.value);
  };

  const cancelAction = (desc, comment) => {
    setDescription("");
    setComments("");
    setselectedCategory({});
    setIsDisabled(false);
    setProgress(false);
    localStorage.setItem("newRequest", false);
    history.goBack();
  };

  const validateForm = () => {
    let errors = {};

    if (!descriptionValue.trim()) {
      errors.description = "Description is required";
    }

    if (!selectedCategory.code) {
      errors.category = "Category is required";
    }

    setErrors(errors);

    if (descriptionValue && selectedCategory.code) {
      setIsDisabled(true);
      setProgress(true);
      let formdetails = {
        serviceTypeCode: selectedCategory.code,
        description: descriptionValue,
        comments: comments,
        requestId: 0,
        workflowCode: "ISSUE_RAISED",
        statusCode: "COMPLETED",
        assignToRole: "CPN",
        assignToUser: null,
      };

      addNewRequest({
        variables: {
          vesselId: +vesselId,
          custId: +custId,
          serviceRecordInput: formdetails,
        },
      });
    }

    return Object.keys(errors).length === 0;
  };

  const [getCategory, { loadingcategory, errorcategory, datacategory }] =
    useLazyQuery(Category_query, {
      fetchPolicy: "cache-and-network",
      onCompleted(resp) {
        let category = resp.serviceRecordTypes.filter(
          (eachCategory) => eachCategory.code == "CRR"
        );
        setserviceCategory(category);
      },
    });

  const [
    addNewRequest,
    {
      loading: newrequestloading,
      error: newrequesterror,
      data: newrequestdata,
    },
  ] = useMutation(ADD_NEW_REQUEST, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      if (resp.upsertServiceRecord === "Success") {
        localStorage.setItem("newRequest", true);
        showToaster();
        setTimeout(() => {
          history.goBack();
        }, 1500);
        setErrors({});
      } else {
        localStorage.setItem("newRequest", true);
        showToasterFailure();
        setTimeout(() => {
          history.goBack();
        }, 1500);
      }
    },
    onError(err) {
      localStorage.setItem("newRequest", true);
      showToasterFailureMessage(err.message);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    },
  });

  return (
    <div className="new-request-container">
      <Form className="new-request-form">
        <div className="form-header">RAISE NEW REQUEST</div>
        <fieldset disabled>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="disabledTextInput">Vessel Name</Form.Label>
            <Form.Control id="disabledTextInput" placeholder={vesselName} />
          </Form.Group>
        </fieldset>
        <Form.Group className="mb-2" controlId="formBasicIssueTitle">
          <Form.Label className="form-label">Description</Form.Label>
          <Form.Control
            onChange={(e) => populateDescriptionValue(e)}
            autoComplete="off"
            maxLength={50}
            name="description"
            value={descriptionValue}
            disabled={isDisabled}
            className={"" + (errors.description ? "error-highlight" : "")}
          />
          {errors.description && (
            <span class="error-msg">{errors.description}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicCategory">
          <Form.Label className="form-label">Category</Form.Label>
          <Dropdown
            value={selectedCategory}
            onChange={(e) => populateCategory(e)}
            options={serviceCategory}
            optionLabel="name"
            placeholder="Select Category"
            className={
              "w-full md:w-14rem " + (errors.category ? "error-highlight" : "")
            }
            disabled={isDisabled}
          />
          {errors.category && <span class="error-msg">{errors.category}</span>}
        </Form.Group>
        <Form.Group className="mb-4" controlId="ControlTextarea">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            onChange={(e) => populateComment(e)}
            autoComplete="off"
            maxLength={250}
            rows={5}
            name="comment"
            value={comments}
            disabled={isDisabled}
          />
        </Form.Group>
        {/* <Form.Group className="mb-2 button-container" controlId="fileUploadControl">
                <Button onClick={handleClick}>
                    <span className='button-text'>Upload file</span>
                </Button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}>
                </input>
            </Form.Group> */}
        <Form.Group className="button-group">
          <Button
            className="cancel-button"
            disabled={isDisabled}
            onClick={() => cancelAction()}
          >
            CANCEL
          </Button>
          {/* <Button className="send-button" onClick={validateForm}>
            SUBMIT
          </Button> */}

          {!showSubmitProgress ? (
            <Button variant="secondary send-button" onClick={validateForm}>
              SUBMIT
            </Button>
          ) : (
            <Pbutton
              label="Submit"
              icon="pi pi-spin pi-spinner"
              iconPos="right"
              className="send-button"
            />
          )}
        </Form.Group>
      </Form>
      <Toast ref={toast} />
    </div>
  );
};

export default NewServiceRequest;
