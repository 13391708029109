import React, { useEffect, useState } from "react";
import OperatorInputText from "./TextField.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ToggleSwitch from "./Switch";
import OperatorRadio from "./Radio";
import FileUpload from "./FileUpload.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Icon } from "@iconify/react";
import moment from "moment";
import { Calendar } from "primereact/calendar";

const InputField = (props) => {
  const {
    register,
    inputProps,
    values,
    control,
    setValue,
    disabled,
    liveData,
    params,
    componentId,
    setUploadFiles,
    selectedDate,
    prevDateTime,
    setOffHireHours,
    setValidArrival,
    setValidDeparture,
    code
  } = props;
  // const [toggleChecked1, setToggleChecked] = useState(false)
  const [formValues, setformValues] = useState({});
  const [liveValue, setLiveValue] = useState();
  const [maxTime, setMaxTime] = useState(new Date());
  const [radTextVal, setRadTextVal] = useState("");
  const [initial, setInitial] = useState(false);
  useEffect(() => {
    if (Object.entries(values).length) {
      Object.entries(values).forEach(([name, value]) => {
        setValue(name, value);
        setformValues((prevData) => {
          return { ...prevData, ...{ [name]: value } };
        });
      });
    } else {
      setformValues({});
      setRadTextVal("");
    }
    if(inputProps.name == 'Fuel Type' && code){
      let name = inputProps.id;
      let value = code;
      setformValues((prevData) => {
        return { ...prevData, ...{ [name]: value } };
      });

    }
  }, [values]);

  useEffect(() => {
    if (!initial) {
      setInitial(true);
    }
  }, [formValues]);


  useEffect(() => {
    if (
      inputProps.sensorId &&
      liveData.sensorval &&
      liveData.sensorval[inputProps.sensorId] !== undefined
    ) {
      setLiveValue(
        liveData.sensorval[inputProps.sensorId].toFixed(2).toString()
      );
    } else if (
      inputProps.sensorId &&
      liveData.errorval &&
      liveData.errorval[inputProps.sensorId] !== undefined
    ) {
      setLiveValue("Error");
    }
  }, [liveData]);

  // useEffect(() => {
  //   if(inputProps.name == 'Fuel Type' && code){
  //     let name = inputProps.id;
  //     let value = code;
  //     setformValues((prevData) => {
  //       return { ...prevData, ...{ [name]: value } };
  //     });
  //     console.log("formValues", inputProps, code, formValues);

  //   }
  // }, [code])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues((prevData) => {
      return { ...prevData, ...{ [name]: value } };
    });
  };

  const handleTextChange = (e, iprops) => {
    if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "number" &&
      iprops.properties.validations.format !== "Integer" &&
      iprops.properties.validations.min &&
      iprops.properties.validations.max
    ) {
      let inputValue = e.target.value;

      if (!/^\d*\.?\d*$/.test(inputValue)) return;

      const decimalIndex = inputValue.indexOf(".");
      if (decimalIndex !== -1) {
        inputValue = inputValue.substring(0, decimalIndex + 3);
      }

      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        if (numericValue < iprops.properties.validations.min) {
          inputValue = iprops.properties.validations.min.toString();
        } else if (numericValue > iprops.properties.validations.max) {
          inputValue = iprops.properties.validations.max.toString();
        }
      }
      e.target.value = inputValue;
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "date"
    ) {
      let dateValue = moment(e.target.value);
      e.target.value = dateValue.format("MM/DD/YYYY");
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "time"
    ) {
      let dateValue = moment(e.target.value);
      // e.target.value = dateValue.format("HH:MM:SS");
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "number" &&
      iprops.properties.validations.format == "Integer" &&
      iprops.properties.validations.min &&
      iprops.properties.validations.max
    ) {
      let inputValue = e.target.value;
      if (!/^\d*$/.test(inputValue)) return;
      const numericValue = parseInt(inputValue);
      if (!isNaN(numericValue)) {
        if (numericValue < iprops.properties.validations.min) {
          inputValue = iprops.properties.validations.min.toString();
        } else if (numericValue > iprops.properties.validations.max) {
          inputValue = iprops.properties.validations.max.toString();
        }
      }
      e.target.value = inputValue;
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "Text Field" &&
      iprops.properties.validations.format == "UN/LOCODE" && 
      iprops.name == "Port of Departure"
    ) {
      let inputValue = e.target.value.toUpperCase();
      if(inputValue.length > 5){
        inputValue = inputValue.slice(0, -1);
      }
      e.target.value = inputValue;
      const regex = /^[A-Z]{5}$/;
      let validation = regex.test(inputValue);
      setValidDeparture(validation)
      
      // if(inputValue.length == 9 && regex.test(inputValue)){
      //   e.target.value
      // }
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "Text Field" &&
      iprops.properties.validations.format == "UN/LOCODE" && 
      iprops.name == "Port of Arrival"
    ) {
      let inputValue = e.target.value.toUpperCase();
      if(inputValue.length > 5){
        inputValue = inputValue.slice(0, -1);
      }
      e.target.value = inputValue;
      const regex = /^[A-Z]{5}$/;
      let validation = regex.test(inputValue);
      setValidArrival(validation)
      
      // if(inputValue.length == 9 && regex.test(inputValue)){
      //   e.target.value
      // }
    }else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "Text Field" &&
      iprops.properties.validations.format == "MGO,HFO,LFO,MDO,LNG" && 
      iprops.name == "Fuel Type"
    ) {
      e.target.value = code;
    } else if (
      iprops.properties.validations != null &&
      iprops.properties.validations.inputType == "Text Field"
    ) {
      e.target.value = e.target.value.trimStart();
    }
    const { name, value } = e.target;

    setformValues((prevData) => {
      return { ...prevData, ...{ [name]: value } };
    });
  };

  // const handleOffhireText = (e) => {
  //   let targetName = e.target.name;
  //   let name1 = targetName.split("*")[0];
  //   let value1 = e.target.value;
  //   setOffHireHours({ name: name1, value: value1 });
  //   setRadTextVal(value1);
  // };

  const handleUpload = (data) => {
    setUploadFiles({ id: data.name, value: data.value, data: data });
  };

  const toggleChecked = (e) => {
    const { name, value, checked } = e.target;
    setformValues((prevData) => {
      return { ...prevData, ...{ [name]: checked ? "true" : "false" } };
    });
  };

  const convertToDate = (dateString) => {
    const [month, day, year] = dateString.split("/").map(Number); // Split and convert to numbers
    return new Date(year, month - 1, day); // month is zero-based
  };

  const convertToTime = (timeString) => {
    let today;
    if(typeof(timeString) == 'string'){
      const [hours, minutes] = timeString.split(":").map(Number);
      const selDate = new Date(prevDateTime);
      let year = selDate.getFullYear();
      let month = selDate.getMonth();
      let day = selDate.getDate();
      today = new Date(year, month, day, hours, minutes, 0, 0);
    }else{
      today = new Date(timeString)
    }
    
    return today;
  };

  return (
    <>
      {inputProps.properties.type === "Text Field" ||
      inputProps.properties.type === "text_field" ? (
        <FormControl
          className="ops-form-control"
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem" }}
        >
          <InputLabel shrink className="ops-label" htmlFor="bootstrap-input">
            {inputProps.name}
            {inputProps.properties?.validations?.required ? (
              <span className="red-star">&nbsp;*</span>
            ) : null}
          </InputLabel>
          {inputProps.properties?.validations?.inputType == "date" ? (
            <Calendar
              dateFormat="mm/dd/yy"
              style={{ height: "45px", marginTop: "5px" }}
              required={inputProps.properties?.validations?.required}
              value={
                formValues[inputProps.id]
                  ? convertToDate(formValues[inputProps.id])
                  : null
              }
              onChange={(e) => handleTextChange(e, inputProps)}
              maxDate={new Date(prevDateTime)}
              name={inputProps.id}
              disabled={disabled}
              inputRef={register}
              id="bootstrap-input-date"
            ></Calendar>
          ) : inputProps.properties?.validations?.inputType == "time" ? (
            <Calendar
              style={{ height: "45px", marginTop: "5px" }}
              required={inputProps.properties?.validations?.required}
              value={
                formValues[inputProps.id]
                  ? convertToTime(formValues[inputProps.id])
                  : null
              }
              onChange={(e) => handleTextChange(e, inputProps)}
              name={inputProps.id}
              disabled={disabled}
              inputRef={register}
              id="bootstrap-input-time"
              timeOnly
            ></Calendar>
          ) : (
            <OperatorInputText
              name={inputProps.id}
              onWheel={(e) => e.target.blur()} 
              disabled={disabled}
              inputRef={register}
              value={formValues[inputProps.id] ? formValues[inputProps.id]  : ""}
              onChange={(e) => handleTextChange(e, inputProps)}
              inputProps={{ autoComplete: "off" }}
              id="bootstrap-input"
              type={inputProps.properties?.validations?.inputType}
              required={inputProps.properties?.validations?.required}
              min={inputProps.properties?.validations?.min}
              max={inputProps.properties?.validations?.max}
            />
          )}
        </FormControl>
      ) : inputProps.properties.type === "Toggle Button" ? (
        <FormControl
          component="fieldset"
          style={{ marginTop: ".6rem", display: "flex", width: "100%" }}
        >
          <FormControlLabel
            control={
              <ToggleSwitch
                name={inputProps.id}
                disabled={disabled}
                value={formValues[inputProps.id] == "true" ? "true" : "false"}
                onChange={toggleChecked}
                checked={formValues[inputProps.id] == "true" ? true : false}
                inputRef={register}
              />
            }
            labelPlacement="start"
            name={inputProps.name.replace(".", "")}
            label={inputProps.name}
            style={{
              marginLeft: "3px",
              fontSize: "1rem",
              color: "rgba(0, 0, 0, 0.54)",
              justifyContent: "space-between",
              wordBreak: "break-all",
            }}
          />
        </FormControl>
      ) : inputProps.properties.type === "Radio Button Group" ? (
        <FormControl
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem", position: "unset" }}
        >
          <FormLabel
            component="legend"
            style={{ fontSize: "1rem", marginBottom: 0 }}
          >
            {inputProps.name}
          </FormLabel>
          <RadioGroup
            row
            name={inputProps.id}
            value={formValues[inputProps.id] ? formValues[inputProps.id] : ""}
            onChange={handleChange}
          >
            {inputProps.properties.options.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option}
                disabled={disabled}
                control={
                  <OperatorRadio
                    inputRef={register}
                    required={inputProps.properties?.validations?.required}
                  />
                }
                label={option}
                style={{ marginRight: "10%" }}
              />
            ))}
          </RadioGroup>
          {/* {inputProps.name == "Offhire_Reasons" ? (
            <input
              name={`${inputProps.id}*text`}
              onChange={handleOffhireText}
              value={radTextVal}
              type="number"
              style={hireReason}
              required
              placeholder="Enter hours"
            />
          ) : null} */}
        </FormControl>
      ) : inputProps.properties.type === "File Upload" ? (
        <FormControl
          component="fieldset"
          style={{ display: "flex", marginTop: ".6rem", position: "unset" }}
        >
          <FormLabel
            component="legend"
            style={{ fontSize: "1rem", marginBottom: 0 }}
          >
            {inputProps.name}
          </FormLabel>
          <FileUpload
            value={formValues[inputProps.id] ? formValues[inputProps.id] : ""}
            name={inputProps.id}
            data={params}
            props={inputProps}
            componentId={componentId}
            onChange={handleUpload}
            selectedDate={selectedDate}
            prevDateTime={prevDateTime}
          ></FileUpload>
        </FormControl>
      ) : (
        ""
      )}
      {liveValue == "Error" ? (
        <>
          <span className="live-val mb-3 d-block">
            Live Value:{" "}
            <Icon
              icon="ion:warning"
              className="text-warning"
              style={{ fontSize: "18px" }}
            />
          </span>{" "}
        </>
      ) : null}
      {liveValue && liveValue !== "Error" ? (
        <span className="live-val mb-3 d-block">Live Value: {liveValue}</span>
      ) : null}
    </>
  );
};
const hireReason = {
  width: "150px",
  height: "45px",
  position: "relative",
  left: "1px",
  borderRadius: "5px",
  border: "1px solid #c5c5c5",
  paddingLeft: "5px",
};
export default InputField;
