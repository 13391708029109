import React, { useContext, useEffect, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Container, Row, Col } from "react-bootstrap";
import check from "../../assets/check.svg";
import { MyContext } from "../Dashboard/Dashboard.js";
import Messages from "../Messages/Messages";
import "./vessel.scss";
import useNetwork from "../../customHooks/useNetwork";
import db from "../../DexieDBConfig";

const Vessel = (props) => {
  let {
    name,
    setVesselName,
    setVesselImage,
    setBgColor,
    setSelectedCustomer,
    forceUpdateMetaData,
    setVesselIMO,
  } = useContext(MyContext);
  const {
    match: { params },
  } = props;
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  //customer id
  const { cid } = params;
  let history = useHistory();

  const networkState = useNetwork();
  const { online } = networkState;
  useEffect(() => {
    setBgColor("#224691");
    if (online) {
      getVessels();
      // getMetaData();
    } else {
      db.userInfo.toArray().then((users) => {
        let theUser = localStorage.getItem("offline_user_email")
          ? localStorage.getItem("offline_user_email")
          : localStorage.getItem("user_email");
        users.map((user) => {
          if (user.email == theUser) {
            user.response.customersByUser.map((cust) => {
              if (cust.customerId == cid) {
                let data = {
                  vesselsByCustomer: cust.vessels,
                };
                setVesselList(data);
              }
            });
          }
        });
      });
    }

    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    localStorage.removeItem("isMrvEnabled");

    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  const Vessel_query = gql`
   query 
   { 
    vesselsByCustomer(id: ${cid})
        {
          id,
          name,
          imo,
          mmsi,
          description,
          imageURL,
          typeDescription,
          displayName,
          contactNumber,
          timezone,
          isMrvEnabled
        }
    }`;

  const [vesselList, setVesselList] = useState();
  const [getVessels, { loading, error, data }] = useLazyQuery(Vessel_query, {
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      db.userInfo.toArray().then((users) => {
        users.map((user) => {
          if (user.email == localStorage.getItem("user_email")) {
            /* istanbul ignore next */
            user.response.customersByUser.map((cust) => {
              if (cust.customerId == cid) {
                let vesselsOnline = [];
                resp.vesselsByCustomer.map((item) => {
                  vesselsOnline.push(item.id);
                });
                let vesselsOffline = [];
                cust.vessels &&
                  cust.vessels.map((item) => {
                    vesselsOffline.push(item.id);
                  });

                let isEqual =
                  JSON.stringify(vesselsOnline.sort()) ===
                  JSON.stringify(vesselsOffline.sort());
                if (!isEqual) {
                  forceUpdateMetaData(cid);
                }
              }
            });
          }
        });
      });
      setVesselList(resp);
    },
  });

  const goToOpsList = (vessel) => {
    setVesselName(vessel.displayName);
    setVesselIMO(vessel.imo);
    setVesselImage(vessel.imageURL);
    localStorage.setItem("vesselIMO", vessel.imo);
    localStorage.setItem("vesselName", vessel.displayName);
    localStorage.setItem("vesselImage", vessel.imageURL);
    if (vessel.isMrvEnabled) {
      localStorage.setItem("isMrvEnabled", true);
    } else {
      localStorage.setItem("isMrvEnabled", false);
    }
    history.push(`/customers/${cid}/vessel/${vessel.id}/operations`);
  };

  return (
    <div className="customers">
      <h5>TRITON Reporter</h5>
      <div className="title">
        <h4 className="welcome">
          Hi <strong>{name}</strong>
        </h4>
        <p className="question">Select a Vessel</p>
      </div>
      <div className="overflow">
        {error && <Messages data={error} />}
        {loading && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={3000000000000}
            />
          </div>
        )}
        {vesselList &&
          vesselList.vesselsByCustomer &&
          vesselList.vesselsByCustomer.length === 0 && (
            <p>No vessels to list!</p>
          )}
        {!isOnline && !!!vesselList && !loading && (
          <span className="msg-content">
            Content is not loading since app has gone offline..
          </span>
        )}
        {vesselList &&
          vesselList.vesselsByCustomer &&
          vesselList.vesselsByCustomer.map((vessel, i) => (
            <div
              className="cust-list"
              key={i}
              onClick={() => goToOpsList(vessel)}
            >
              <div className="list-box">
                <Container>
                  <Row>
                    <Col xs={3}>
                      <div className="user-box">
                        <img src={vessel.imageURL} alt="" />
                      </div>
                    </Col>
                    <Col xs={9}>
                      <div className="name">
                        <h4 className="cust-name">{vessel.displayName}</h4>
                        {vessel.imo && <h6>IMO - {vessel.imo}</h6>}
                        <img className="check" src={check} alt="check" />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Vessel;
