import React, { useContext, useState, useEffect, useRef } from "react";
import { Container, Col, Button, Row } from "react-bootstrap";
import "./ServiceDetails.scss";
import { useHistory } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { MyContext } from "../Dashboard/Dashboard";
import Loader from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Toast } from "primereact/toast";
import CloseButton from "react-bootstrap/CloseButton";
import moment from "moment";
import check from "../../assets/check.svg";


const ServiceDetails = (props) => {
  let {
    serviceRecordList,
    vesselIMO,
    vesselImage,
    vesselName,
    setServiceRecordList,
  } = useContext(MyContext);
  const toast = useRef(null);
  let history = useHistory();
  const {
    match: { params },
  } = props;

  const { cid, vid, serviceId } = params;
  //let vesselName =  localStorage.getItem("vesselName");
  // let vesselImage = localStorage.getItem("vesselImage");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [serviceWorkflowList, setWorkflowDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledApprove, setIsDisabledApprove] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [userRole, setUserRole] = useState("");
  const [disableButton, setDisableRequestButton] = useState(false);
  const [actionType, setAction] = useState("");
  const [workFlowPending, setWorkFlowPending] = useState([]);
  const [assignToUser, setCreatedUserId] = useState("");
  const [createdUserRole, setCreatedUserRole] = useState("");
  const [createdUser, setCreatedUser] = useState("");
  const [assignedToRole, setAssignedToRole] = useState("");
  const [vesselRefresh, setVesselName] = useState("");
  const [serviceListDetails, setServiceDetails] = useState([]);
  const [serviceAssignee, setAssigneeDetails] = useState([]);

  useEffect(() => {
    getRequestDetails();
    localStorage.setItem("service", serviceRecordList);
    serviceRecordList = serviceRecordList
      ? serviceRecordList
      : localStorage.getItem("service");
    let serviceStore = localStorage.getItem("service");
    setIsDisabled(false);
    setIsDisabledApprove(false);
  }, []);

  useEffect(() => {
  }, [serviceRecordList]);
  useEffect(() => {}, [vesselIMO]);
  useEffect(() => {}, [vesselImage]);
  useEffect(() => {}, [vesselName]);

  const showToaster = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Request Submitted Successfully",
      life: 3000,
    });
  };

  const showToasterFailure = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Request Submission Failed",
      life: 3000,
    });
  };

  const populateCommentValue = (event) => {
    setCommentValue(event.target.value);
  };

  const cancelAction = () => {
    setCommentValue("");
    setShow(false);
    setIsDisabledApprove(false);
    setIsDisabled(false);
  };

  const details_query = gql`
  query{serviceRecordDetails(vesselId:${vid},custId:${cid},requestId:${serviceId})
  {currentWorkflowDetails{workflow,status,comments,assignedDate,
    assignedToRole,assignedToUser,createdUserRole,createdUser,createdUserId,
    ,approverRoleCode,approverRoleName,estimateStartDate,
    estimateEndDate},assigneeJob,createdDate,serviceRequestTypeCode,description,title,pendingWorkFlows}}`;

  const [getRequestDetails, { loading, error, data }] = useLazyQuery(
    details_query,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(resp) {
        if (resp) {
          let modifiedArray = JSON.parse(
            JSON.stringify(resp.serviceRecordDetails.currentWorkflowDetails)
          );
          setServiceDetails(resp.serviceRecordDetails);
          setAssigneeDetails(resp.serviceRecordDetails.currentWorkflowDetails[0]);
          let workFlowSteps = JSON.parse(
            JSON.stringify(resp.serviceRecordDetails.pendingWorkFlows)
          );
          let workFlowStepsPending = JSON.parse(
            JSON.stringify(resp.serviceRecordDetails.pendingWorkFlows)
          );
          let last = modifiedArray[modifiedArray.length - 1];
          if (last.statusCode !== "cancelled") {
            setWorkflowDetails(modifiedArray);
            workFlowStepsPending.splice(0, 1);
            setWorkFlowPending(workFlowStepsPending);
            let capObj = {
              comments: "",
              createdUserRole: last.assignedToRole,
              createdUser: last.assignedToUser ? last.assignedToUser : "",
              assignedDate: last.assignedDate,
              status: "Pending",
              workflow: workFlowSteps[0],
              manipulated: true,
              approverRoleName:last.approverRoleName,
              approverRoleCode:last.approverRoleCode
            };
            setCreatedUserId(last.createdUserId);
            setCreatedUserRole(last.createdUserRole);
            setCreatedUser(last.createdUser);
            setAssignedToRole(last.assignedToRole);
            modifiedArray = Object.assign([], this.modifiedArray);
            modifiedArray.push(capObj);
            let array = [
              ...resp.serviceRecordDetails.currentWorkflowDetails,
              ...modifiedArray,
            ];
            setWorkflowDetails(array);
          } else {
            setWorkflowDetails(modifiedArray);
            setCreatedUserId(last.createdUserId);
            setCreatedUserRole(last.createdUserRole);
            setCreatedUser(last.createdUser);
            setAssignedToRole(last.assignedToRole);
            setWorkFlowPending(workFlowStepsPending);
          }
        }
      },
    }
  );

  const APPROVE_DENY_REQUEST = gql`
    mutation upsertServiceRecord(
      $vesselId: Int!
      $custId: Int!
      $serviceRecordInput: ServiceRecordInput!
    ) {
      upsertServiceRecord(
        vesselId: $vesselId
        custId: $custId
        serviceRecordInput: $serviceRecordInput
      )
    }
  `;

  const approveDenyAccess = (requestDetail, action) => {
    setIsDisabled(true);
    setIsDisabledApprove(true);
    setAction(action);
    let formdetails = {
      serviceTypeCode: requestDetail.serviceRequestTypeCode,
      description: requestDetail.title,
      comments: requestDetail.description,
      requestId: +serviceId,
      workflowCode: "REQUEST_APPROVAL",
      statusCode: action === "approve" ? "COMPLETED" : "CANCELLED",
      assignToRole: "SER",
      assignToUser: assignToUser,
    };
    approveDenyRequest({
      variables: {
        vesselId: +vid,
        custId: +cid,
        serviceRecordInput: formdetails,
        action: action,
      },
    });
  };

  const [
    approveDenyRequest,
    {
      loading: approveDenyloading,
      error: approveDenyerror,
      data: approveDenydata,
    },
  ] = useMutation(APPROVE_DENY_REQUEST, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      if (resp.upsertServiceRecord === "Success") {
        showToaster();
        setTimeout(
          () =>
            history.push(
              `/customers/${cid}/vessel/${vid}/operations/serviceRequest`
            ),
          3500
        );
      }
    },
    onError(err) {
      showToasterFailure();
      setTimeout(
        () =>
          history.push(
            `/customers/${cid}/vessel/${vid}/operations/serviceRequest`
          ),
        3500
      );
    },
  });

  const getDateFormat = (date) => {
    return moment.utc(date).local().format("DD-MM-YYYY HH:mm:ss a");
  };

  const getDateReadableFormat = (date) => {
    let date1 = date / 1000;
    return moment.unix(date1).format("DD-MM-YYYY HH:mm:ss a");
  };

  const getDuration = (service) => {
    let startDate = service.estimateStartDate / 1000;
    let endDate = service.estimateEndDate / 1000;

    var a = moment.unix(startDate).format("YYYY-MM-DD HH:mm:ss");
    var b = moment.unix(endDate).format("YYYY-MM-DD HH:mm:ss");

    var a1 = moment(a);
    var b1 = moment(b);

    var duration = moment.duration(b1.diff(a1));
    var hours = Math.round(duration.asHours());

    var minutes = parseInt(duration.asMinutes()) % 60;

    if (minutes > 0) {
      if (hours > 1) {
        return hours + " hours " + minutes + " minutes";
      } else {
        return hours + " hour " + minutes + " minutes";
      }
    } else {
      if (hours > 1) {
        return hours + " hours";
      } else {
        return hours + " hour";
      }
    }
  };

  return (
    <div className="customers service-details-wrapper">
      <h5>TRITON Reporter</h5>
      {loading && (
        <div className="loader">
          <Loader
            type="TailSpin"
            color=" #2546B1"
            height={80}
            width={80}
            timeout={300000}
          />
        </div>
      )}
      {!loading && (
        <>
          <div className="title">
            <div className="request-header">REQUEST DETAILS</div>
            <div className="welcome">
              <div className="user-box">
                {localStorage.getItem("vesselImage") &&
                localStorage.getItem("vesselImage") !== "null" ? (
                  <img src={localStorage.getItem("vesselImage")} alt="" />
                ) : (
                  <img className="check" src="user-icon" alt="" />
                )}
              </div>
              {vesselName ? (
                <div className="vessel-name">{vesselName}</div>
              ) : (
                <div className="vessel-name">
                  {localStorage.getItem("vesselName")}
                </div>
              )}
              {localStorage.getItem("vesselIMO") &&
              localStorage.getItem("vesselIMO") !== "null" ? (
                <div className="vessel-number">
                  IMO : {localStorage.getItem("vesselIMO")}
                </div>
              ) : (
                <div className="vessel-number">IMO : N.A</div>
              )}
            </div>
          </div>
          <div className="issue-description-wrapper-chief-engineer">
            <Row className="issue-container">
              <div className="issue-description">
                {serviceListDetails.title}
              </div>
              <div className="issue-time-status">
                <div className="issue-timestamp">
                  {getDateFormat(serviceListDetails.createdDate)}
                </div>
                <div className="issue-status-description">
                  {workFlowPending.length > 0 ? (
                    <span className="pending">
                      {serviceListDetails.assigneeJob !==
                      "Service Engineer"
                        ? serviceAssignee.approverRoleName !== null
                          ? "Pending with " +
                          serviceAssignee.approverRoleName
                          : "Pending"
                        : "Pending with " +
                        serviceListDetails.assigneeJob}
                    </span>
                  ) : (
                    <span className="approved">{serviceAssignee.status}</span>
                  )}
                </div>
              </div>
              <div className="detailed-description">
                <div className="description-header">DESCRIPTION</div>
                <div className="description-details">
                  {serviceListDetails.description}
                </div>
              </div>
            </Row>
          </div>
          <div className="workflow-wrapper-chief-engineer">
            <div className="workflow-header">WORKFLOW</div>
            <div className="workflow-steps">
              {serviceWorkflowList.map((workflow, i) =>
                workflow.manipulated ? (
                  <div
                    className={
                      workflow?.approverRoleCode ===
                        localStorage.getItem("role") &&
                      workflow.status == "Pending" &&
                      workflow.workflow == "Request Approval"
                        ? "step-summary active-workflow"
                        : "step-summary"
                    }
                    key={i}
                  >
                    <div className="worflow-diagram-chief-engineer">
                      <div className="workflow-round">
                        {workflow.status === "Completed" ||
                        workflow.status === "Cancelled" ? (
                          <div className="workflow-round-completed"></div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="workflow-arrow"></div>
                    </div>
                    <div className="step-details">
                      <div className="step-description">
                        {workflow.workflow}
                      </div>
                      <div className="step-timestamp">
                        {getDateFormat(workflow.assignedDate)}
                      </div>
                    </div>
                    <div className="step-approval">
                      <div className="step-approver-details">
                        <div className="created-user">
                          {workflow.createdUser}
                        </div>
                        <div className="created-user-role">
                          {workflow.createdUserRole === "Service Engineer" ? (
                            <>{workflow.createdUserRole}</>
                          ) : workflow.approverRoleName !== null ? (
                            "Pending with " +
                            workflow.approverRoleName
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="!serviceRecordList.isAssignee ? step-status : step-status button">
                        {localStorage.getItem("role") !== null &&
                        workflow?.approverRoleCode ===
                          localStorage.getItem("role") &&
                        workflow.status == "Pending" &&
                        workflow.workflow == "Request Approval" ? (
                          <Button
                            className="pending-button"
                            onClick={handleShow}
                            disabled={disableButton}
                          >
                            Take Action
                          </Button>
                        ) : (
                          <div className="workflow.status==''? '': pending-status">
                            {workflow.status}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="step-summary" key={i}>
                    <div className="worflow-diagram-chief-engineer">
                      <div className="workflow-round">
                        {workflow.status === "Completed" ||
                        workflow.status === "Cancelled" ? (
                          <div className="workflow-round-completed"></div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="workflow-arrow"></div>
                    </div>
                    <div className="step-details">
                      <div className="step-description">
                        {workflow.workflow}
                      </div>
                      <div className="step-timestamp">
                        {getDateFormat(workflow.assignedDate)}
                      </div>
                    </div>
                    <div className="step-approval">
                      <div className="step-approver-details">
                        <div className="created-user">
                          {workflow.createdUser}
                        </div>
                        <div className="created-user-role">
                          {workflow.createdUserRole}
                        </div>
                      </div>
                      <div className="step-status">{workflow.status}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="pending-steps-wrapper">
            <div className="task-header">TO BE STARTED</div>

            <div className="task-list-body">
              {workFlowPending.map((step, index) => (
                <div className="task-list-details" key={index}>
                  <div className="workflow-round"></div>
                  <div className="task-name">{step}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="access-approve-modal-wrapper-chief-engineer">
            <Modal
              className="access-approve-modal-chief-engineer"
              animation={false}
              show={show}
              onHide={handleClose}
              backdrop={false}
              style={{ background: "white" }}
            >
              <Modal.Header>
                <div className="access-request-header-chief-engineer">
                  ACCESS REQUEST
                </div>
                <CloseButton onClick={cancelAction}></CloseButton>
              </Modal.Header>
              <Modal.Body>
                <div className="service-provider-details-wrapper">
                  <div className="service-provider-image">
                    <img class="user-icon"></img>
                  </div>
                  <div className="service-provider-name">
                    {createdUserRole} has requested access
                  </div>
                  <div className="vessel-details">
                    <div className="welcome">
                      <div className="user-box">
                        {localStorage.getItem("vesselImage") &&
                        localStorage.getItem("vesselImage") !== "null" ? (
                          <img
                            src={localStorage.getItem("vesselImage")}
                            alt=""
                          />
                        ) : (
                          <img src="user-icon" alt="" />
                        )}
                      </div>
                      {vesselName ? (
                        <div className="vessel-name">{vesselName}</div>
                      ) : (
                        <div className="vessel-name">
                          {localStorage.getItem("vesselName")}
                        </div>
                      )}
                      {localStorage.getItem("vesselIMO") &&
                      localStorage.getItem("vesselIMO") !== "null" ? (
                        <div className="vessel-number">
                          IMO : {localStorage.getItem("vesselIMO")}
                        </div>
                      ) : (
                        <div className="vessel-number">IMO : N.A</div>
                      )}
                    </div>
                  </div>
                  <div className="service-request-reason">
                    <div className="reason-header">DESCRIPTION :</div>
                    <div className="reason-description">
                      {serviceListDetails.description}
                    </div>
                  </div>
                  <div className="service-requestedBy-details">
                    <div className="service-requestBy-label">
                      REQUESTED BY :
                    </div>
                    <div className="service-requestor-name">{createdUser}</div>
                  </div>
                  <div className="service-request-timestamp">
                    <div className="service-requesttimestamp-label">
                      REQUESTED ON :
                    </div>
                    <div className="service-request-timestamp">
                      {getDateFormat(serviceListDetails.createdDate)}
                    </div>
                  </div>
                  <div className="service-desired-duration">
                    <div className="service-desired-label">DESIRED START :</div>
                    <div className="service-desired-timestamp">
                      {getDateReadableFormat(
                        serviceAssignee.estimateStartDate
                      )}
                    </div>
                  </div>
                  <div className="service-request-duration">
                    <div className="service-requestduration-label">
                      DURATION :
                    </div>
                    <div className="service-duration-timestamp">
                      {getDuration(serviceAssignee)}
                    </div>
                  </div>

                  <div className="approval-denial-comments">
                    <Form.Group className="mb-0" controlId="ControlTextarea">
                      <Form.Label>COMMENTS :</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={(e) => populateCommentValue(e)}
                        maxLength={250}
                        disabled={isDisabled}
                      />
                    </Form.Group>
                  </div>
                  <div className="approve-deny-button-group">
                    <Button
                      variant="secondary deny-button"
                      onClick={() =>
                        approveDenyAccess(serviceListDetails, "reject")
                      }
                      disabled={isDisabled}
                    >
                      DENY
                    </Button>
                    <Button
                      variant="secondary approve-button"
                      onClick={() =>
                        approveDenyAccess(serviceListDetails, "approve")
                      }
                      disabled={isDisabledApprove}
                    >
                      APPROVE
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
      <Toast ref={toast} />
    </div>
  );
};

export default ServiceDetails;
