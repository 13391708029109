import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../Dashboard/Dashboard.js";
import { Container, Row, Col } from "react-bootstrap";
import dailyround from "../../assets/daily-round.svg";
import bvround from "../../assets/bv-round.svg";
import eventmarker from "../../assets/event_history.svg";
import "./Operations.scss";
import { useHistory } from "react-router-dom";
import useNetwork from "../../customHooks/useNetwork";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import Loader from "react-loader-spinner";
import { MdEngineering } from "react-icons/md";
import { MdSupervisorAccount } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";

const Operations = (props) => {
  let {
    name,
    customerId,
    vesselIMO,
    vesselImage,
    vesselName,
    serviceRecordList,
    setBvRoom,
    bvRoom,
  } = useContext(MyContext);
  let history = useHistory();

  useEffect(() => {
    sessionStorage.setItem(
      "serviceRecordList",
      JSON.stringify(serviceRecordList)
    );
  }, [serviceRecordList]);
  useEffect(() => {
    sessionStorage.setItem("vesselIMO", vesselIMO);
  }, [vesselIMO]);
  useEffect(() => {
    sessionStorage.setItem("vesselImage", vesselImage);
  }, [vesselImage]);
  useEffect(() => {
    sessionStorage.setItem("vesselName", vesselName);
  }, [vesselName]);

  const {
    match: { params },
  } = props;
  //vessel id
  const { cid, vid } = params;
  const Roles_query = gql`
    query{appVesselUserRole(custId:${cid},vesselId:${vid}){code,role}}`;
  const [getRoles, { loading, error, data }] = useLazyQuery(Roles_query, {
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      if (resp && resp.appVesselUserRole && resp.appVesselUserRole.code) {
        setCurrentUserRole(resp.appVesselUserRole.code);
        setCurrentUserRoleName(resp.appVesselUserRole.role);
      } else {
        // console.log("role", resp.appVesselUserRole);
        setReadOnlyAccess(resp.appVesselUserRole);
      }
    },
  });
  const [roleCode, setCurrentUserRole] = useState();
  const [roleName, setCurrentUserRoleName] = useState();
  const [readOnlyRole, setReadOnlyAccess] = useState();

  const goToRoomsList = (bv) => {
    localStorage.setItem('mrvRoomEnabled', bv)
    setBvRoom(bv);
    history.push(`/customers/${cid}/vessel/${vid}/operations/rooms`);
  };
  const goToEventList = () => {
    history.push(`/customers/${cid}/vessel/${vid}/operations/events`);
  };
  const goToServiceRequest = () => {
    if (roleCode == "CER" || roleCode == "CPN" || roleCode == "SPT") {
      localStorage.setItem("role", roleCode);
      history.push(`/customers/${cid}/vessel/${vid}/operations/serviceRequest`);
    } else if (readOnlyRole == null) {
      history.push(
        `/customers/${cid}/vessel/${vid}/operations/serviceRequestReadOnly`
      );
    }
  };
  useEffect(() => {
    getRoles();
    localStorage.setItem("newRequest", false);
    let mrvEnabled = localStorage.getItem("isMrvEnabled");
    if (mrvEnabled == "true") {
      setBvRoom(true);
    }else{
      setBvRoom(false);
    }
  }, []);

  return (
    <div className="customers">
      <h5>TRITON Reporter</h5>
      <div className="title">
        <h4 className="welcome">
          Hi <strong>{name}</strong>
        </h4>
        <p className="question">Select an action</p>
      </div>
      <div className="overflow">
        {loading && (
          <div className="loader">
            <Loader
              type="TailSpin"
              color=" #2546B1"
              height={80}
              width={80}
              timeout={3000000000000}
            />
          </div>
        )}
        <Container>
          <Row>
            <Col xs={6}>
              <div className="room-box" onClick={() => goToRoomsList(false)}>
                <Container>
                  <Row>
                    <Col xs={6}>
                      <div className="sec-title">Daily Round Check</div>
                    </Col>
                    <Col xs={6}>
                      <img
                        src={dailyround}
                        alt="daily-round"
                        className="daily-round"
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
            {bvRoom ? (
              <Col xs={6}>
                <div className="room-box" onClick={() => goToRoomsList(true)}>
                  <Container>
                    <Row>
                      <Col xs={6}>
                        <div className="sec-title">MRV Bureau Veritas</div>
                      </Col>
                      <Col xs={6}>
                        <img
                          src={bvround}
                          alt="daily-round"
                          className="daily-round"
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            ) : null}

            <Col xs={6}>
              <div className="room-box" onClick={() => goToEventList()}>
                <Container>
                  <Row>
                    <Col xs={6}>
                      <div className="sec-title">Events Marker</div>
                    </Col>

                    <Col xs={6}>
                      <img
                        src={eventmarker}
                        alt="daily-round"
                        className="daily-round"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="damen-orange"></div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
            <Col xs={6}>
              <div className="room-box" onClick={() => goToServiceRequest()}>
                <Container>
                  <Row>
                    <Col xs={6}>
                      <div className="sec-title">Service Request</div>
                      {roleCode == "CER" && (
                        <div>
                          <MdEngineering className="eng-icon"></MdEngineering>
                        </div>
                      )}
                      {roleCode == "CPN" && (
                        <div>
                          <FaUserTie className="cap-icon"></FaUserTie>
                        </div>
                      )}
                      {roleCode == "SPT" && (
                        <div>
                          <MdSupervisorAccount className="ship-icon"></MdSupervisorAccount>
                        </div>
                      )}
                    </Col>

                    <Col xs={6}>
                      <FaCogs className="daily-round service-request"></FaCogs>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="damen-orange"></div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Operations;
