import React, { useContext, useState, useEffect } from "react";
import { Container, Col, Button, Row } from "react-bootstrap";
import "./ServiceRequest.scss";
import { useHistory } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { Paginator } from "primereact/paginator";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { MyContext } from "../Dashboard/Dashboard";
import Loader from "react-loader-spinner";
import moment from "moment";

const ServiceRequest = (props) => {
  let { setServiceRecordList, vesselIMO, vesselImage, vesselName } =
    useContext(MyContext);
  let history = useHistory();
  const {
    match: { params },
  } = props;
  const { cid, vid } = params;
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [serviceDetailList, setServiceDetails] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [code, setCode] = useState("RAR");

  useEffect(() => {
    setFirst(1);
    setRows(10);
    getRequestList();
    if (localStorage.getItem("newRequest") === "true") {
      setActiveIndex(1);
      setCode("CRR");
    }
  }, []);

  useEffect(() => {}, [vesselIMO]);
  useEffect(() => {}, [vesselImage]);
  useEffect(() => {}, [vesselName]);

  const onPageChange = (event) => {
    if (event) {
      setFirst(1);
      setRows(event.rows);
    }
  };

  const setIndexTabContent = (e) => {
    setActiveIndex(e.index);
    setFirst(1);
    setRows(10);
    setServiceDetails([]);
    if (e.index === 0) {
      setCode("RAR");
      if (e.index === 0 && code === "RAR") {
        getRequestList();
      }
    } else {
      setCode("CRR");
      if (e.index === 1 && code === "CRR") {
        getRequestList();
      }
    }
  };

  const list_query = gql`
  query{serviceRequestListByVesselParty(vesselId:${vid},custId:${cid},pageNumber:${first},pageSize:${rows},serviceRequestTypeCode:"${code}")
  {requestId,serviceRequestTypeCode,comments,description,approverRoleCode,approverRoleName,
    createdDate,status,estimateStartDate,estimateEndDate,
    customer,vessel,isAssignee,currentAssigneeJob}}`;

  const [getRequestList, { loading, error, data }] = useLazyQuery(list_query, {
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      if (
        resp.serviceRequestListByVesselParty &&
        resp.serviceRequestListByVesselParty.length
      ) {
        setServiceDetails(resp.serviceRequestListByVesselParty);
      }
    },
    onError(error) {},
  });

  const goToServiceDetailswithID = (service) => {
    if (service) {
      setServiceRecordList(service);
      if (service.serviceRequestTypeCode == "RAR") {
        localStorage.setItem("newRequest", false);
      } else {
        localStorage.setItem("newRequest", true);
      }
    }
    history.push(
      `/customers/${cid}/vessel/${vid}/operations/serviceRequest/serviceDetails/${service.requestId}`
    );
  };

  const goToNewServiceRequest = () => {
    history.push(
      `/customers/${cid}/vessel/${vid}/operations/newServiceRequest`
    );
  };

  const getDateFormat = (date) => {
    return moment.utc(date).local().format("DD-MM-YYYY HH:mm:ss");
  };

  return (
    <div className="customers service-request-wrapper">
      <div className="header-section">
        <h5>TRITON Reporter</h5>
        <div className="title">
          <div className="welcome">
            <div className="user-box">
            {localStorage.getItem("vesselImage") &&
                localStorage.getItem("vesselImage") !== "null" ? (
                  <img src={localStorage.getItem("vesselImage")} alt="" />
                ) : (
                  <img src="user-icon" alt="" />
                )}
            </div>
            <div className="vessel-name">
              {localStorage.getItem("vesselName")}
            </div>
            {localStorage.getItem("vesselIMO") &&
            localStorage.getItem("vesselIMO") !== "null" ? (
              <div className="vessel-number">
                IMO : {localStorage.getItem("vesselIMO")}
              </div>
            ) : (
              <div className="vessel-number">IMO : N.A</div>
            )}
          </div>
          <div className="sub-header">Service Requests</div>
        </div>
      </div>

      <div className="service-request">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setIndexTabContent(e)}
        >
          <TabPanel header="Assigned">
            {loading && (
              <div className="loader">
                <Loader
                  type="TailSpin"
                  color=" #2546B1"
                  height={80}
                  width={80}
                  timeout={300000}
                />
              </div>
            )}
            {!loading && (
              <Container fluid="md">
                {serviceDetailList.length > 0 ? (
                  serviceDetailList.map((service, i) => (
                    <Row
                      className="service-request-details-chief-engineer"
                      key={i}
                    >
                      <Col
                        className="row-details p-3"
                        onClick={() => goToServiceDetailswithID(service)}
                      >
                        <div className="general-description">
                          {service.description}
                        </div>
                        <div className="specific-details">
                          <div className="request-timestamp">
                            {getDateFormat(service.createdDate)}
                          </div>
                          <div className="request-status">
                            {service.status == "" ? (
                              <span className="pending">
                                {service.currentAssigneeJob !==
                                "Service Engineer"
                                  ? service.approverRoleName !== null
                                    ? "Pending with " + service.approverRoleName
                                    : "Pending"
                                  : "Pending with " +
                                    service.currentAssigneeJob}
                              </span>
                            ) : (
                              <span className="approved">{service.status}</span>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="no-record">No Records Found</div>
                )}
              </Container>
            )}
          </TabPanel>
          <TabPanel header="Raised">
            {" "}
            {loading && (
              <div className="loader">
                <Loader
                  type="TailSpin"
                  color=" #2546B1"
                  height={80}
                  width={80}
                  timeout={300000}
                />
              </div>
            )}
            {!loading && (
              <Container fluid="md">
                {serviceDetailList.length > 0 ? (
                  serviceDetailList.map((service, i) => (
                    <Row
                      className="service-request-details-chief-engineer"
                      key={i}
                    >
                      <Col
                        className="row-details p-3"
                        onClick={() => goToServiceDetailswithID(service)}
                      >
                        <div className="general-description">
                          {service.description}
                        </div>
                        <div className="specific-details">
                          <div className="request-timestamp">
                            {getDateFormat(service.createdDate)}
                          </div>
                          <div className="request-status">
                            {service.status == "" ? (
                              <span className="pending">
                                {service.currentAssigneeJob !==
                                "Service Engineer"
                                  ? service.approverRoleName !== null
                                    ? "Pending with " + service.approverRoleName
                                    : "Pending"
                                  : "Pending with " +
                                    service.currentAssigneeJob}
                              </span>
                            ) : (
                              <span className="approved">{service.status}</span>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="no-record">No Records Found</div>
                )}
              </Container>
            )}
          </TabPanel>
        </TabView>
      </div>
      <div className="service-request-remote-access">
        <div className="card">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={serviceDetailList.length}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
        <Button
          className="request-access-button"
          onClick={() => goToNewServiceRequest()}
          style={{ display: "none"}}
        >
          Raise Request
        </Button>
      </div>
    </div>
  );
};

export default ServiceRequest;
