import Dexie from "dexie";
import { applyEncryptionMiddleware, cryptoOptions } from "dexie-encrypted";

const nacl = require("tweetnacl");

const key = process.env.REACT_APP_XTGHL;
const customSeed = key;
const seedArray = new Uint8Array(customSeed.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
const keyPair = nacl.sign.keyPair.fromSeed(seedArray);

// Initialize Dexie with your database name
const db = new Dexie("TritonOpsSecure");

// Define the store schema in Dexie
db.version(6).stores({
  unsyncedOperations: "valueId, UTCtimeStamp, Uid, VRid, user, value, type",
  syncedOperations: "valueId, value, userName, finishedTime",
  submittedOperations: "valueId, value, UTCtimestamp",
  userInfo: "email, uid, userName, pin, response, timeStamp",
  versionInfo: "email, custId, version, vessels",
  imageStore: "id, url",
  files: "id, name, type, content, cid, vid, rid, imgType",
  comments: "id, data",
  images: "id, data, time",
  uploadFiles: "id, data, gid, value"
});

//to encrypt a store

applyEncryptionMiddleware(db, keyPair.publicKey, {
  images: { type: cryptoOptions.ENCRYPT_LIST, fields: ["data", "time"] }, // Fields to be encrypted
  comments: { type: cryptoOptions.ENCRYPT_LIST, fields: ["data"] },
  userInfo: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["pin","response"],
  },
  files: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["name", "type", "content", "cid", "vid", "rid", "imgType"],
  },
  versionInfo: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["customers"],
  },
  imageStore: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["url"]
  },
  // unsyncedOperations: {
  //   type: cryptoOptions.ENCRYPT_LIST,
  //   fields: ["value"]
  // },
  syncedOperations: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["value"]
  },
  uploadFiles: {
    type: cryptoOptions.ENCRYPT_LIST,
    fields: ["data"]
  }
});


/*istanbul ignore next */
db.open().catch((err) => {
  console.log("Failed to open db: ", err.stack || err);
});

export default db;
