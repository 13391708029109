import React, { useContext, useState } from "react";
import { MyContext } from "../Dashboard/Dashboard.js";
import { Container, Col, Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./ServiceDetailsSuper.scss";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ParameterForm from "./form";
import SelectBox from "../Select/Select.js";

const ServiceDetailsSuper = (props) => {
  let { name } = useContext(MyContext);
  let history = useHistory();
  const {
    match: { params },
  } = props;
  const { cid, vid } = params;
  let vesselName = localStorage.getItem("vesselName");
  let vesselImage = localStorage.getItem("vesselImage");

  //Check-Deny Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Input comments Modal
  const [showInput, setShowInput] = useState(false);
  const handleCloseInput = () => setShowInput(false);
  const handleShowInput = () => setShowInput(true);

  //Parameter Modal
  const [showDataCheck, setShowData] = useState(false);
  const handleCloseData = () => setShowData(false);
  const handleShowData = () => setShowData(true);

  //Assign Modal
  const [showAssign, setAssign] = useState(false);
  const handleAssignClose = () => setAssign(false);
  const handleAssignShow = () => setAssign(true);

  const openCommentDialog = () => {
    setShow(false);
    setShowInput(true);
    setShowData(false);
  };

  const openDataCheckDialog = () => {
    setShow(false);
    setShowInput(false);
    setShowData(true);
  };
  const closeDialogs = () => {
    setShow(false);
    setShowInput(false);
    setShowData(false);
  };

  const openAssignDialog = () => {
    setShow(false);
    setShowInput(false);
    setShowData(false);
    setAssign(true);
  };

  const [parameterData, setParameterData] = useState([]);

  const tableRows = parameterData.map((info) => {
    return (
      <tr>
        <td>{info.name}</td>
        <td>{info.pvalue}</td>
        <td>{info.comment}</td>
      </tr>
    );
  });

  const addRows = (data) => {
    const totalparams = parameterData.length;
    data.id = totalparams + 1;
    const updatedParameterData = [...parameterData];
    updatedParameterData.push(data);
    setParameterData(updatedParameterData);
  };

  const serviceProviderList = [
    { value: "Service Provider 1", label: "Service Provider 1" },
    { value: "Service Provider 2", label: "Service Provider 1" },
  ];

  return (
    <div className="customers service-details-wrapper">
      <h5>TRITON Reporter</h5>
      <div className="title">
        <div className="request-header">Request Details</div>
        <div className="welcome">
          <div className="user-box">
            <img src={vesselImage} alt="" />
          </div>
          <div className="vessel-name">{vesselName}</div>
          <div className="vessel-number">IMO : {vid}</div>
        </div>
      </div>
      <div className="issue-description-wrapper-super">
        <Row className="issue-container">
          <div className="issue-description">Abnormal Sound from Engine</div>
          <div className="part-specification">Main Engine</div>
          <div className="issue-time-status">
            <div className="issue-timestamp">5th July 2021</div>
            <div className="issue-status-description">Pending with Superintendant</div>
          </div>
          <div className="detailed-description">
            <div className="description-header">Description</div>
            <div className="description-details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore.
            </div>
            <div className="description-images-super">
              <img
                src="https://picsum.photos/200/300?random=1"
                alt="image1"
                width="100"
                height="50"
              ></img>
              <img
                src="https://picsum.photos/200/300?random=2"
                alt="image2"
                width="100"
                height="50"
              ></img>
            </div>
          </div>
        </Row>
      </div>
      <div className="workflow-wrapper">
        <div className="workflow-header">Workflow</div>
        <div className="workflow-steps">
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round"></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Issue Reported</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">
                John Smith ,Chief Engineer
              </div>
              <div className="step-status">Completed</div>
            </div>
          </div>
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round"></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Captain's Approval</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">James ,Captain</div>
              <div className="step-status">Completed</div>
            </div>
          </div>
          <div className="step-summary" onClick={handleShow}>
            <div className="worflow-diagram">
              <div className="workflow-round"></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Data Check</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">James,Superintendant</div>
              <div className="step-status pending">Pending</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pending-steps-wrapper">
        <div className="task-header">To be started</div>
        <div className="task-list-body">
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Estimate Time and Data</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Remote Access Approval</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Report Work</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Review Work</div>
          </div>
        </div>
      </div>
      <div className="datacheck-modal-wrapper" onClick={handleClose}>
        <Modal
          className="data-check-modal"
          animation={false}
          show={show}
          onHide={handleClose}
          backdrop={false}
          style={{ background: "transparent" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Modal.Body>
            <div className="approve-deny-button-group">
              <Button
                variant="secondary deny-button"
                onClick={() => openCommentDialog()}
              >
                DENY
              </Button>
              <Button
                variant="secondary approve-button"
                onClick={handleShowData}
              >
                CHECK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="comment-modal-wrapper">
        <Modal
          className="comment-modal"
          show={showInput}
          onHide={handleCloseInput}
          animation={false}
          backdrop={false}
          style={{ background: "transparent", height: "26" }}
        >
          <Modal.Body>
            <Form.Group className="mb-0" controlId="ControlTextarea">
              <Form.Label>Comments</Form.Label>
              <Form.Control as="textarea" rows={5} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="comment-button-group">
              <Button variant="secondary close-form" onClick={handleCloseInput}>
                CANCEL
              </Button>
              <Button
                variant="secondary submit-approval"
                onClick={handleCloseInput}
              >
                SUBMIT
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="parameter-modal-wrapper">
        <Modal
          className="parameter-modal"
          show={showDataCheck}
          animation={false}
          backdrop={false}
          style={{ background: "white" }}
        >
          <Modal.Body>
            <div className="parameter-form-wrapper">
              <div className="parameter-form">
                <ParameterForm func={addRows} />
              </div>
              <div className="parameter-table-entry">
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer> */}
          <div className="comment-button-group">
            <Button
              variant="secondary close-form"
              onClick={() => closeDialogs()}
            >
              CANCEL
            </Button>
            <Button
              variant="secondary submit-approval"
              onClick={() => openAssignDialog()}
            >
              ASSIGN
            </Button>
          </div>
        </Modal>
      </div>
      <div className="assign-modal-wrapper">
        <Modal
          className="assign-modal"
          show={showAssign}
          onHide={handleAssignClose}
          animation={false}
          backdrop={false}
          style={{ background: "transparent", height: "26" }}
        >
          <Modal.Body>
            <div class="assign-form-wrapper">
            <div className="request-assign-form-header">Request Assignment</div>
              <Form.Label className="form-label">Assign</Form.Label>
              <SelectBox options={serviceProviderList} />
              <Form.Group className="mb-0" controlId="ControlTextarea">
                <Form.Label>Comments</Form.Label>
                <Form.Control as="textarea" rows={5} />
              </Form.Group>
            </div>
          </Modal.Body>
            <div className="comment-button-group">
              <Button
                variant="secondary close-form"
                onClick={handleAssignClose}
              >
                CANCEL
              </Button>
              <Button
                variant="secondary submit-approval"
                onClick={handleAssignClose}
              >
                SUBMIT
              </Button>
            </div>
        </Modal>
      </div>
    </div>
  );
};

export default ServiceDetailsSuper;
