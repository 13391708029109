import React, { useState, useEffect, useContext } from "react";
import "./login.scss";
import logo from "../../assets/LargeLogo_new.png";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import PinInput from "react-pin-input";
import useNetwork from "../../customHooks/useNetwork";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../../containers/App";
import db from "../../DexieDBConfig";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
// import { loginRequest } from "../../helpers/authConfig";
// import { callMsGraph } from "../../helpers/graph";
import { useIsAuthenticated } from "@azure/msal-react";

const Login = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [user, setUserState] = useState({});
  const [api, setApi] = useState({});
  // const [inProgress, setInProgress] = useState(false);
  const [loggedIn, setloggedIn] = useState(false);
  let [pinSetter, setPinSetter] = useState(false);
  const networkState = useNetwork();

  const { online } = networkState;
  let history = useHistory();

  useEffect(() => {
    // setInProgress(false);
    setloggedIn(false);
    getMyUser();
    
    db['userInfo'].toArray().then((users: any) => {
      if (users.length > 0) {
        setOfflineUsers(true);
        setUserList(users);
      }
    });
    // msalInstance.handleRedirectPromise().then((resp) => {
    //   console.log('Login redierct', resp)
    //   if (resp && resp.account) msalInstance.setActiveAccount(resp.account);
    // });
  }, []);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      setloggedIn(true);
      // instance
      localStorage.removeItem("offline_user_email");
      history.push("/dashboard");
    }
  }, [isAuthenticated]);
  const [offlineUser, setOfflineUsers] = useState(false);
  const [userList, setUserList] = useState([]);
  const userLogin = () => {
    msalInstance.loginRedirect({ scopes: ["User.Read"] }).catch((e) => {
      console.log(e);
    });
    
  };

  const getMyUser = () => {
    
  };
  const [selectedUser, setSelectedUser] = useState({ pin: "", email: "" });
  const onUserSelect = (user) => {
    setPinSetter(true);
    setSelectedUser(user);
  };
  const [invalidPin, showInvalidPin] = useState(false);
  const pinCheck = (value) => {
    if (value.length == 5) {
      if (selectedUser.pin == value) {
        showInvalidPin(false);
        localStorage.setItem("offline_user", selectedUser.email);
        localStorage.setItem("offline_user_email", selectedUser.email);
        history.push("/dashboard");
      } else {
        showInvalidPin(true);
      }
    } else {
      showInvalidPin(false);
    }
  };

  const handleClose = () => {
    setPinSetter(false);
  };

  return (
    <div className="App">
      <div className="head">
        <img className="logo" src={logo} alt="logo" />
        <h1 className="title">Reporter</h1>
      </div>
      {online ? (
        <>
          {/* {!loggedIn ? ( */}
          <div className="body">
            {inProgress != "none" ? (
              <Loader
                type="ThreeDots"
                color=" #fff"
                height={80}
                width={80}
                timeout={3000}
              />
            ) : (
              <Button className="login-btn" onClick={userLogin}>
                Login
              </Button>
            )}
          </div>
          {/* ) : (
            <h5 className="auth-text">Authentiating..</h5>
          )} */}
        </>
      ) : (
        <div className="container">
          {userList.length == 0 ? (
            <p style={{ color: "#fff" }}>
              Device is offline. No offline users found!
            </p>
          ) : (
            <p style={{ color: "#fff" }}>
              Device is offline. Click on your email id to proceed offline.
            </p>
          )}
          {userList.map((user: any) => {
            return (
              <div className="row">
                <div className="col-1"></div>
                <div
                  className="offline-user col-10"
                  onClick={() => onUserSelect(user)}
                >
                  <p className="offline-email">{user.email.split("@")[0]}</p>
                  <AiOutlineArrowRight className="proceed-icon" />
                </div>
                <div className="col-1"></div>
              </div>
            );
          })}
        </div>
      )}
      <Modal size="lg" centered show={pinSetter} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Offline login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Please enter your secret pin</h4>
            <PinInput length={5} focus type="numeric" onChange={pinCheck} />
            {invalidPin ? (
              <p className="text-danger">Pin entered is not correct</p>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default withRouter(Login);
