
import React, { useContext, useState, useEffect } from 'react';
import { MyContext } from '../Dashboard/Dashboard.js';
import { Container, Col,Button, Row, } from 'react-bootstrap';
import './ServiceRequestSuper.scss'
import { useHistory } from 'react-router-dom';

const ServiceRequestSuper = (props) => {
    let { name} = useContext(MyContext);
    let history = useHistory();
    const { match: { params } } = props;
    //vessel id
    const { cid, vid } = params;
    let vesselName =localStorage.getItem("vesselName")
    let vesselImage =localStorage.getItem("vesselImage")

    // const goToServiceDetails =(serviceId) => {
    //     history.push(`/customers/${cid}/vessel/${vid}/operations/serviceRequest/serviceDetails/${serviceId}`)
    // }
    const goToServiceDetailsSuper =() => {
        history.push(`/customers/${cid}/vessel/${vid}/operations/ServiceDetailsSuper`)
    }

    const goToServiceDetailsResolvedCaptain =() => {
        history.push(`/customers/${cid}/vessel/${vid}/operations/serviceDetailsResolvedCaptain`)
    }

    const goToServiceDetailsAccess =() => {
        history.push(`/customers/${cid}/vessel/${vid}/operations/serviceDetailsAccessPending`)
    }


    return (
        <div className="customers service-request-wrapper">
            <h5>TRITON Reporter</h5>
            <div className="title">
                <div className="welcome">
                      <div className="user-box">
                        <img src={vesselImage} alt="" />
                      </div>
                    <div class="vessel-name">{vesselName}</div>
                    <div class="vessel-number">IMO : {vid}</div>
                </div>
                <div class="sub-header">
                    Service Requests
                </div>
            </div>
            {/* {posts.map((post) => ( */}
            <div class="service-request">
                <Container fluid="md">
                    <div className='pending-service-request'>
                        <div className='pending-request-header'>
                            PENDING FOR APPROVAL
                        </div>
                    <Row className="service-request-details" onClick={() => goToServiceDetailsSuper()}>
                        <Col className="row-details p-3">
                            <div class="general-description">Abnormal Sound from Engine</div>
                            <div class="specific-details">
                                <div class="request-timestamp">5th May 2021</div>
                                <div class="request-status">Pending with Superintendant</div>
                            </div>
                        </Col>
                    </Row>
                    </div>
                    <div className='remaining-service-request'>
                    <div className='remaining-request-header'>OTHER REPORTED ISSUES</div>
                    <Row className="service-request-details" onClick={() => goToServiceDetailsAccess()}>
                        <Col className="row-details p-3">
                            <div class="general-description">Machine Maintenance Required</div>
                            <div class="specific-details">
                                <div class="request-timestamp">5th May 2021</div>
                                <div class="request-status-progress">Work in Progress</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="service-request-details" onClick={() => goToServiceDetailsResolvedCaptain()}>
                        <Col className="row-details p-3">
                            <div class="general-description">Battery Over Heated</div>
                            <div class="specific-details">
                                <div class="request-timestamp">5th May 2021</div>
                                <div class="request-status-progress">Resolved</div>
                            </div>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </div>
            {/* ))} */}
        </div>
    );
}

export default ServiceRequestSuper;